@import "node_modules/bootstrap/scss/bootstrap";
// @import "~datatables.net-bs5/css/dataTables.bootstrap5";
// @import "node_modules/datatables.net-buttons-bs5/css/buttons.bootstrap5";

@font-face {
    font-family: "Helvetica";
    src: url("/fonts/Helvetica/Helvetica.ttf") format("ttf");
    src: url("/fonts/Helvetica/Helvetica-Bold.ttf") format("ttf");
    src: url("/fonts/Helvetica/Helvetica-BoldOblique.ttf") format("ttf");
    src: url("/fonts/Helvetica/Helvetica-Oblique.ttf") format("ttf");
  }

.link-unstyled {
    color: inherit;
    text-decoration: inherit;
}

.link-unstyled:hover {
    color: inherit;
    text-decoration: inherit;
}

.btn {
    color: white !important;
}

@media (max-width: 768px) {
    .desktop_only {
        display: none;
    }
}

@media (min-width: 768px) {
    .mobile_only {
        display: none;
    }
}
